<template>
  <div :class="['play', `holiday-${currentHoliday.id}`, `page-${page}`]">
    <ModalLevelUp
      v-if="!isDemoUser && isShowLevelUpModal && page === 'sound'"
      @close="isShowLevelUpModal = false"
      @levelUp="onLevelUp"
    />

    <GameHeader
      v-if="page === 'sound'"
      :canBackward="canBackward"
      :canForward="canForward"
      :useHelp="showHelp"
      :soundName="sound && sound.name"
      @help="onUseHelpEye"
      @backward="onChangeSound($metrika.consts.GAME_BACKWARD, backSound)"
      @forward="onChangeSound($metrika.consts.GAME_FORWARD, isDemoUser ? nextSoundDemo : nextSound)"
    />

    <template v-if="!loading && sound && ['sound', 'author', 'finish'].includes(page)">
      <GamePageSound
        v-if="page === 'sound'"
        :sound="sound"
        :user="user"
        @finish="finishSound"
      >
        <template v-slot:audio>
          <GameHeaderAudio
            :can-play="user.sound_playing"
            :source="currentLibrary.sound"
            @backSounds="backSounds"
            @toggleAudio="toggleAudio"
          />
        </template>
        <template v-slot:difficulty>
          <GameHeaderDifficulty
            v-if="!isDemoUser && sumGuessedSounds >= 5"
            @up="onLevelUp"
            @down="onLevelDown"
          />
        </template>
      </GamePageSound>

      <GamePageAuthor
        v-else-if="page === 'author'"
        :authors="authors"
        :sound="sound"
        @finish="finishAuthor"
      />

      <GamePageFinish
        v-else-if="page === 'finish'"
        :scores="scores.sound"
        @nextGame="onNextGame"
      />
    </template>
    <Preloader v-else color="#fff"/>
  </div>
</template>


<script>
import _ from 'lodash';
import axios from 'axios';
import DateFilter from '@/filters/date.filter';

import Preloader from '../Preloader';
import GameHeader from './GameHeader';
import GameHeaderAudio from './GameHeaderAudio.vue';
import GameHeaderDifficulty from './GameHeaderDifficulty.vue';
import GamePageSound from './GamePageSound';
import GamePageAuthor from './GamePageAuthor';
import GamePageFinish from './GamePageFinish';
import ModalLevelUp from "./ModalLevelUp.vue";

import { mapState, mapGetters, mapActions, mapMutations } from 'vuex';

export default {
  name: 'GamePlayMain',

  components: {
    Preloader,
    ModalLevelUp,
    GamePageSound,
    GamePageAuthor,
    GamePageFinish,
    GameHeader,
    GameHeaderAudio,
    GameHeaderDifficulty,
  },

  data: () => ({
    page: 'sound',
    showHelp: false,
    authors: {},
    scores: {},
    info: {},

    isEditing: true,
    currentLibrary: null,
    audio: null,
    isShowLevelUpModal: false,
    isShowLevelControl: false,
  }),

  watch: {
    library: {
      deep: true,
      handler: 'continueAudio',
    },
    'sound._id'() {
      this.showHelp = !!this.sound.name;
      this.showHelp && this.setShowSoundName(true);
    },
    sumGuessedSounds: 'showLevelUpModal',
    page: 'showLevelUpModal',
  },

  computed: {
    ...mapState({ completedSounds: state => state.game.completedSounds }),
    ...mapGetters([
      'isDemoUser',
      'isDemoCompleted',
      'difficultyStatuses',
      'currentSoundIndex',
      'soundOfHistory',
      'historySounds',
      'axiosOptions',
      'orientation',
      'settingsGame',
      'selectedStock',
      'settings',
      'library',
      'scoresValues',
      'sound',
      'user',
      'token',
      'loading',
      'tokenGame',
      'startGameTime',
      'canForwardDemo',
      'canBackwardDemo',
      'currentDifficulty',
      'currentHoliday',
      'sumGuessedSounds',
    ]),
    existNextSound: ths => ths.sound || _.has(ths.historySounds[ths.currentSoundIndex + 1], 'id'),
    canBackward() {
      if (this.isDemoUser) return this.canBackwardDemo;
      return this.currentSoundIndex > 0 || this.difficultyStatuses.loadAllSounds
    },
    canForward() {
      if (this.isDemoUser) return this.canForwardDemo;
      return !this.loading && !!this.existNextSound;
    },
  },

  beforeMount() {
    window.addEventListener('beforeunload', this.preventNav);
  },

  async mounted() {
    this.createGameDuration();
    this.getGameSettings();
    this.getGameLibrary();
    await this.getGameSounds({ withToken: true });
    if (this.isDemoCompleted && this.isDemoUser) return this.logout();
    if (this.isDemoUser) this.$bus.$emit('showInstuction');
    setInterval(async () => {
      try {
        if (!this.user._id) return;
        const params = { _id: this.user._id };
        const headers = { token: this.$store.getters.token };
        const response = await axios('api/newsfeed/has-hotnews', { headers, params });
        this.$store.dispatch('setHotNewsStatus', response.status === 200);
      } catch(err) {
        console.error(err);
      };
    }, 15000);
  },

  async beforeDestroy() {
    window.removeEventListener('beforeunload', this.preventNav);
    _.invoke(this.audio, 'pause');
  },

  beforeRouteLeave(to, from, next) {
    next();
  },

  methods: {
    ...mapMutations([
      'updateCompletedSounds',
    ]),
    ...mapActions([
      'getGameSettings',
      'getGameLibrary',
      'getGameScores',
      'getGameSpeeds',
      'getGameSounds',
      'onSoundSkip',
      'createGameDuration',
      'useNameTipBySoundId',
      'setShowSoundName',
      'addGuessedSound',
      'prepareToNextGame',
      'nextSoundDemo',
      'nextSound',
      'backSound',
      'showADS',
      'isDefaultUser',
      'changeDifficultyLevel',
      'getAuthorsBySoundId',
      'finishGame',
      'logout',
    ]),

    showLevelUpModal() {
      if (this.sumGuessedSounds !== 5) return;
      if (this.sound.difficulty.name != 1) return;
      this.isShowLevelUpModal = true;
    },

    hideSoundName() {
      this.setShowSoundName(false);
      this.showHelp = false;
    },

    async onUseHelpEye() {
      if (!this.sound.name) await this.useNameTipBySoundId();
      const isShow = !this.soundOfHistory.showName;
      this.showHelp = isShow;
      isShow && this.$metrika.add(this.$metrika.consts.GAME_USE_HELP_EYE);
      this.setShowSoundName(isShow);
    },

    onLevelUp() {
      this.isShowLevelUpModal = false;
      if (this.currentDifficulty >= 5) return;
      this.onChangeSound(this.$metrika.consts.GAME_LEVEL_UP, this.nextSound.bind(this));
      this.changeDifficultyLevel(this.currentDifficulty + 1);
      this.updateCompletedSounds({ sameLevel: 0 });
      this.showHelp = false;
    },

    onLevelDown() {
      if (this.currentDifficulty <= 1) return;
      this.onChangeSound(this.$metrika.consts.GAME_LEVEL_DOWN, this.nextSound.bind(this));
      this.changeDifficultyLevel(this.currentDifficulty - 1);
      this.updateCompletedSounds({ sameLevel: 0 });
      this.showHelp = false;
    },

    onChangeSound(eventName, callback = _.noop) {
      const prevTrackGamePayload = _.cloneDeep(this.$store.getters['metrika/currentGamePayload']);
      this.updateCompletedSounds({ inRow: 0 });
      setTimeout(() => {
        callback();
        this.$metrika.add(eventName);
        this.sendTookMetrika(prevTrackGamePayload);
        if (this.isDefaultUser) window.__advRunFullscreen?.('init');
        this.$store.dispatch('updateSubsription');
      });
    },

    sendTookMetrika(prevTrackGamePayload) {
      const timeDifferent = Date.now() - (this.startGameTime || Date.now());
      this.createGameDuration();
      if (!timeDifferent) return;
      const tookSecond = Math.floor(timeDifferent / 1000);
      this.$metrika.add(
        this.$metrika.consts.GAME_SOUND_TIMECOST, 
        _.defaultsDeep({ game: _.cloneDeep(prevTrackGamePayload) }, { game: { tookSecond } }), 
        [0, 1, 3, 5, 10, 15, 20, 30, 45, 60, 90, 120, 180, 300, 600].reverse().find(t => t <= tookSecond),
      );
      this.hideSoundName();
    },

    preventNav(event) {
      if (!this.isEditing) return;
      event.preventDefault();
      this.$store.dispatch('checkGameDuration');
      event.returnValue = "";
    },

    async finishSound({ time, words }) {
      try {
        this.info = { words };
        this.info.date = DateFilter(new Date(), 'datehour', true, true);
        this.info.duration = Math.ceil((time.end - time.start) / 1000);
        this.$metrika.add(this.$metrika.consts.GAME_SOUND_FINISH);
        this.authors = await this.getAuthorsBySoundId();
        this.updateCompletedSounds({
          perSession: 1 + this.completedSounds.perSession,
          sameLevel: 1 + this.completedSounds.sameLevel,
          inRow: 1 + this.completedSounds.inRow,
        });
        this.sendTookMetrika(this.$store.getters['metrika/currentGamePayload']);
        if (this.authors.length) return (this.page = 'author');
        this.finishAuthor(null);
      // this.addGuessedSound();
      // this.dataCollection(date, seconds, data);
      } catch(err) {
        console.error(err);
      };
    },

    async finishAuthor(authorId) {
      try {
        const payload = Object.assign({ authorId, demo: this.isDemoUser }, this.info);
        const response = await this.finishGame(payload);
        this.scores = response.scores;
        this.page = 'finish';
        const game = { authorId };
        game.scores = _.omit(response.scores.sound, ['difficultyCoefficient', 'speedCoefficient']);
        this.$metrika.add(this.$metrika.consts.GAME_AUTHOR_FINISH, { game });
      } catch(err) {
        console.error(err);
      };
    },

    // Отправка метрики, вшить в событие finish
    dataCollection(date, seconds, data) {
      try {
        const body = { date, seconds };
        body.quantity_currect_answer = data.quantity_currect_answer;
        body.quantity_helps = data.quantity_helps;
        body.sound_id = this.sound._id;
        axios.put(`/api/analytics/sound`, body, this.axiosOptions);
      } catch(err) {
        console.error(err);
      };
    },
    // Надо переделать
    async onNextGame() {
      this.page = 'loading';
      this.$metrika.add(this.$metrika.consts.GAME_CONTINUE);
      await this.prepareToNextGame();
      await this.getGameSounds({ limit: 1 });
      this.page = 'sound';
      this.hideSoundName();
      if (!this.isDemoUser) return this.isDefaultUser && window.__advRunFullscreen?.('init');
      if (!this.isDemoCompleted) return;
      // await this.completeDemo();
      this.$store.dispatch('logout');
      this.$router.push('/auth');
    },

    // Не понятно зачем
    continueAudio(status = false) {
      if (!_.isBoolean(status)) status = false;
      let random = () => {
        const arr = this.library.slice();
        const sound = arr[Math.floor(Math.random() * arr.length)];
        return sound;
      };
      this.library.forEach(el => el.played = false);
      this.currentLibrary = random();
      this.currentLibrary.played = true;
      this.backSounds(status);
    },

    // Не понятно зачем
    backSounds(status = false) {
      if (this.user.sound_playing || status) {
        this.audio = new Audio(this.currentLibrary.sound);
        this.audio.status = false;
        this.audio.volume = 0.05;
        if (this.audio.status)
          this.audio.play();
        this.$bus.$emit('audioStatus', false);
        this.audio.onended = () => {
          this.currentLibrary = this.library.find(el => !el.played);
          if (!this.currentLibrary) {
            this.continueAudio(status);
          } else {
            this.currentLibrary.played = true;
            this.backSounds(status);
          }
        };
      };
    },

    // Не понятно зачем
    toggleAudio() {
      this.audio.status = !this.audio.status;
      localStorage.setItem('soundPlaying', this.audio.status);
      if (this.audio.status) this.audio.play();
      else this.audio.pause();
      this.$bus.$emit('audioStatus', this.audio.status);
    },
  },
};
</script>


<style lang="scss" scoped>
.bgImage {
  margin: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.blobCont {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
  top: 0;
  left: 0;
  object-fit: cover;
}

@for $i from 1 through 18 {
  $a: #{$i*90};
  $b: #{$i*90+360};

  .blob:nth-child(#{$i}) {
    animation: move#{$i} 40s infinite linear;
  }

  @keyframes move#{$i} {
    from {
      transform: rotate(#{$a}deg) translate(200px, 0.1px) rotate(-#{$a}deg);
    }

    to {
      transform: rotate(#{$b}deg) translate(200px, 0.1px) rotate(-#{$b}deg);
    }
  }
}

.play {
  min-height: 100%;
  background-color: #b392f0;
  background-size: 150px;
  margin: 0 ;
  padding: 0 25px;
  display: grid;
  background-image: url(../../../assets/patterns/game/default.svg);

  &.holiday-valentine {
    background-image: url(../../../assets/patterns/game/valentines_day.svg);
  }

  &.holiday-newyear {
    background-image: url(../../../assets/patterns/game/newyear.svg);
  }

  &.page-sound {
    grid-auto-rows: 150px 1fr;
  }

  .muting-body {
    height: 75px;
    display: flex;
    justify-content: center;
  }
}
</style>