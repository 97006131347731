<template>
  <div class="finish">
    <div class="portrait">
      <div class="container">
        <div class="title">Поздравляем!</div>
        <div class="content">
          <div class="score-details">
            <p
              v-for="(value, key) in lodash.omit(scoresExtended, ['all'])"
              style="color: #B392F0"
              :key="key"
              v-text="value"
            />
            <p 
              style="color: #4E4B66; font-weight: 500;"
              v-text="scoresExtended.all"
            />
          </div>
        </div>
      </div>
      <div class="actions">
        <button 
          class="btn-finish" 
          @click="$emit('nextGame')"
          v-text="'Новая игра'"
        />
      </div>
    </div>
  </div>
</template>


<script>
import _ from 'lodash';

export default {
  name: 'GamePageFinish',

  constants: {
    scoreTemplates: {
      all: 'Итого: +{{num}} {{text}}',
      words: 'За слова: +{{num}} {{text}}',
      author: 'За автора: +{{num}} {{text}}',
      difficulty: 'За сложность: +{{num}} {{text}}',
      tipWords: 'Подсказки слов: {{num}} {{text}}',
      tipName: 'Подсказка названия: {{num}} {{text}}',
      speedCoefficient: 'Множитель скорости: x{{num}}',
    },
  },

  props: {
    scores: {
      type: Object,
      required: true,
    },
  },

  data: () => ({
    lodash: _,
  }),

  computed: {
    scoresExtended() {
      return _.reduce(this.scores, (acc, score, key) => {
        if (!score) return acc;
        if (key.endsWith('Coefficient') && score === 1) return acc;
        return Object.assign(acc, { [key]: this.useTemplate(key, score) });
      }, {});
    },
  },

  methods: {
    useTemplate(templateId, score) {
      const template = _.get(this.$options, `constants.scoreTemplates[${templateId}]`, '');
      return template.replace('{{num}}', score).replace('{{text}}', this.numWord(score));
    },

    numWord(value, words = ['балл', 'балла', 'баллов']) {
      value = Math.abs(value) % 100;
      var num = value % 10;
      if(value > 10 && value < 20) return words[2];
      if(num > 1 && num < 5) return words[1];
      if(num == 1) return words[0];
      return words[2];
    },
  },
};
</script>


<style lang="scss" scoped>
#app-container > div > div.play > div.finish > div {
  background-color: #fff;
  padding: 25px;
  border-radius: 25px;
  width: 100%;
}

.container {
  color: #14142B;
}

.play[data-v-066712ed] {
  height: 100%;
  background-color: #fff !important;
  margin: 0;
  padding: 0 25px;
}

.finish {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Montserrat';

  .title {
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 40px;
    align-items: center;
    text-align: center;
    color: #14142B;
  }

  .total {
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.75px;
    color: #14142B;
    padding-top: 45px;
  }

  .score {
    font-style: normal;
    font-weight: 400;
    font-size: 15px !important;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.75px;
    padding: 5px 0 20px 0;
  }

  .actions {
    button:first-child {
      margin-bottom: 15px;
    }
  }
}

.btn-white {
  background-color: transparent !important;
  border: none !important;
  color: #4E4B66;
  font-size: 15px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.75px;
  text-align: center;
  cursor: pointer;
}

.btn-finish {
  color: #4700AB;
  background: #A996FF;
  border: 3px solid #4E4B66;
  border-radius: 12px;
  width: 90%;
  border-radius: 12px;
  padding: 16px 24px 16px 24px;
  display: block;
  margin: 0 auto;
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0.75px;
  cursor: pointer;
}

.btn-author {
  padding: 16px 24px;
  gap: 12px;
  display: block;
  margin: 0 auto;
  border: none;
  background-color: rgb(169 150 255 / 50%);
  color: rgb(71 0 171 / 75%);
  font-size: 15px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.75px;
  text-align: center;
}

.score-details {
  padding: 10px 10px 20px 10px;
  p {
    text-align: center;
    line-height: 2;
  }
}
</style>